import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/**
 * @requireAuth  true/false 需要/不需要openid
 */

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue'),
      meta: {
        title: '兽医首选',
        // requireAuth: sessionStorage.getItem('userAgent') === 'wechat'
        requireAuth: true
      }
    },
    // 宠医首页
    {
      path: '/home/index',
      name: 'index',
      component: () => import('../views/home/index.vue'),
      meta: {
        title: '优选商城',
        requireAuth: false,
        keepAlive: true
      }
    },
    // 宠医认证
    {
      path: '/home/preCertification',
      name: 'preCertification',
      component: () => import('../views/home/preCertification.vue'),
      meta: {
        title: '资质认证',
        requireAuth: false
      }
    },
    {
      path: '/home/certification',
      name: 'certification',
      component: () => import('../views/home/certification.vue'),
      meta: {
        title: '资质认证',
        requireAuth: false
      }
    },
    // 登录注册页面集群
    {
      path: '/user/register',
      name: 'register',
      component: () => import('../views/user/register.vue'),
      meta: {
        title: '注册',
        requireAuth: false
      }
    },
    {
      path: '/user/login',
      name: 'login',
      component: () => import('../views/user/login.vue'),
      meta: {
        title: '用户登录',
        requireAuth: false
      }
    },
    {
      path: '/user/szxq_info',
      name: 'szxq_info',
      component: () => import('../views/user/szxq_info.vue'),
      meta: {
        title: '补充资料',
        requireAuth: false
      }
    },
    {
      path: '/user/agreement',
      name: 'agreement',
      component: () => import('../views/user/agreement.vue'),
      meta: {
        title: '注册协议',
        requireAuth: false
      }
    },
    {
      path: '/user/privacy',
      name: 'privacy',
      component: () => import('../views/user/privacy.vue'),
      meta: {
        title: '隐私政策',
        requireAuth: false
      }
    },
    {
      path: '/user/error',
      name: 'error',
      component: () => import('../views/user/error.vue'),
      meta: {
        title: '友情提示',
        requireAuth: false
      }
    },
    {
      path: '/user/my',
      name: 'my',
      component: () => import('../views/user/my.vue'),
      meta: {
        title: '个人中心',
        requireAuth: false
      }
    },
    {
      path: '/user/myInfo',
      name: 'myInfo',
      component: () => import('../views/user/myInfo.vue'),
      meta: {
        title: '个人信息',
        requireAuth: false
      }
    },
    {
      path: '/user/vipDetail',
      name: 'vipDetail',
      component: () => import('../views/user/vipDetail.vue'),
      meta: {
        title: '个人中心',
        requireAuth: false
      }
    },
    {
      path: '/user/vipAuth',
      name: 'vipAuth',
      component: () => import('../views/user/vipAuth.vue'),
      meta: {
        title: '个人中心',
        requireAuth: false
      }
    },
    {
      path: '/user/coupon',
      name: 'coupon',
      component: () => import('../views/user/coupon.vue'),
      meta: {
        title: '优惠券',
        requireAuth: false
      }
    },
    {
      path: '/user/addressList',
      name: 'addressList',
      component: () => import('../views/user/addressList.vue'),
      meta: {
        title: '收货地址',
        requireAuth: false
      }
    },
    {
      path: '/user/addressDetail',
      name: 'addressDetail',
      component: () => import('../views/user/addressDetail.vue'),
      meta: {
        title: '编辑地址',
        requireAuth: false
      }
    },
    {
      path: '/user/favor',
      name: 'favor',
      component: () => import('../views/user/favor.vue'),
      meta: {
        title: '我的收藏',
        requireAuth: false
      }
    },
    {
      path: '/user/invite',
      name: 'invite',
      component: () => import('../views/user/invite.vue'),
      meta: {
        title: '邀请有礼',
        // requireAuth: false
        requireAuth: true
      }
    },
    {
      path: '/user/inviteReg',
      name: 'inviteReg',
      component: () => import('../views/user/inviteReg.vue'),
      meta: {
        title: '注册有礼',
        requireAuth: false
      }
    },
    {
      path: '/user/inviteReg2',
      name: 'inviteReg2',
      component: () => import('../views/user/inviteReg2.vue'),
      meta: {
        title: '注册有礼',
        requireAuth: false
      }
    },
    {
      path: '/user/inviteRegSuccess',
      name: 'inviteRegSuccess',
      component: () => import('../views/user/inviteRegSuccess.vue'),
      meta: {
        title: '注册成功',
        requireAuth: false
      }
    },
    {
      path: '/product/searchpro',
      name: 'searchpro',
      component: () => import('../views/product/searchpro.vue'),
      meta: {
        title: '商品搜索',
        requireAuth: false
      }
    },
    {
      path: '/product/subjectPage_bsbj',
      name: 'subjectPage_bsbj',
      component: () => import('../views/product/subjectPage_bsbj.vue'),
      meta: {
        title: '商品搜索',
        requireAuth: false,
        keepAlive: true
      }
    },
    {
      path: '/product/searchproList_cart',
      name: 'searchproList_cart',
      component: () => import('../views/product/searchproList_cart.vue'),
      meta: {
        title: '商品搜索',
        requireAuth: false,
        keepAlive: true
      }
    },
    // {
    //   path: '/product/fastPorCart',
    //   name: 'fastPorCart',
    //   component: () => import('../views/product/fastPorCart.vue'),
    //   meta: {
    //     title: '兽医首选特惠采',
    //     requireAuth: false,
    //     keepAlive: true
    //   }
    // },
    {
      path: '/product/searchproList',
      name: 'searchproList',
      component: () => import('../views/product/searchproList.vue'),
      meta: {
        title: '商品搜索',
        requireAuth: false,
        keepAlive: true
      }
    },
    {
      path: '/product/detail',
      name: 'proDetail',
      component: () => import('../views/product/detail.vue'),
      meta: {
        title: '商品详情',
        // requireAuth: false
        requireAuth: true
      }
    },
    {
      path: '/product/detail_cart',
      name: 'proDetail',
      component: () => import('../views/product/detail_cart.vue'),
      meta: {
        title: '商品详情',
        requireAuth: true
      }
    },
    {
      path: '/product/classify',
      name: 'proClassify',
      component: () => import('../views/product/classify.vue'),
      meta: {
        title: '商品分类',
        requireAuth: false,
        keepAlive: true
      }
    },
    {
      path: '/product/classify_cart',
      name: 'proClassify',
      component: () => import('../views/product/classify_cart.vue'),
      meta: {
        title: '兽医首选特惠采',
        requireAuth: true,
        keepAlive: true
      }
    },
    {
      path: '/product/classifyList',
      name: 'proClassifyList',
      component: () => import('../views/product/classifyList.vue'),
      meta: {
        title: ' ',
        requireAuth: false,
        keepAlive: true
      }
    },
    {
      path: '/product/brandList',
      name: 'proBrandList',
      component: () => import('../views/product/brandList.vue'),
      meta: {
        title: ' ',
        requireAuth: false,
        keepAlive: true
      }
    },
    {
      path: '/product/tagIdProList',
      name: 'proBrandList',
      component: () => import('../views/product/tagIdProList.vue'),
      meta: {
        title: ' ',
        requireAuth: false,
        keepAlive: true
      }
    },
    {
      path: '/product/activerProList',
      name: 'activerProList',
      component: () => import('../views/product/activerProList.vue'),
      meta: {
        title: ' ',
        requireAuth: false,
        keepAlive: true
      }
    },
    {
      path: '/product/recommendProList',
      name: 'recommendProList',
      component: () => import('../views/product/recommendProList.vue'),
      meta: {
        title: ' ',
        requireAuth: false,
        keepAlive: true
      }
    },
    {
      path: '/product/liveList',
      name: 'liveList',
      component: () => import('../views/product/liveList.vue'),
      meta: {
        title: '直播',
        requireAuth: false,
        keepAlive: true
      }
    },
    {
      path: '/cart/cart',
      name: 'cart',
      component: () => import('../views/cart/cart.vue'),
      meta: {
        title: '购物车',
        requireAuth: false,
        keepAlive: true
      }
    },
    // 订单区域
    {
      path: '/order/subOrder',
      name: 'subOrder',
      component: () => import('../views/order/subOrder.vue'),
      meta: {
        title: '确认订单',
        requireAuth: false
      }
    },
    {
      path: '/order/subOrder_cart',
      name: 'subOrder_cart',
      component: () => import('../views/order/subOrder_cart.vue'),
      meta: {
        title: '确认订单',
        requireAuth: false
      }
    },
    {
      path: '/order/billPay',
      name: 'billPay',
      component: () => import('../views/order/billPay.vue'),
      meta: {
        title: '确认订单',
        requireAuth: false
      }
    },
    {
      path: '/order/payResult',
      name: 'payResult',
      component: () => import('../views/order/payResult.vue'),
      meta: {
        title: '支付结果',
        requireAuth: false
      }
    },
    {
      path: '/order/orderList',
      name: 'orderList',
      component: () => import('../views/order/orderList.vue'),
      meta: {
        title: '订单列表',
        requireAuth: false,
        keepAlive: true
      }
    },
    {
      path: '/order/orderReturnList',
      name: 'orderReturnList',
      component: () => import('../views/order/orderReturnList.vue'),
      meta: {
        title: '售后列表',
        requireAuth: false
      }
    },
    {
      path: '/order/shiplist',
      name: 'shiplist',
      component: () => import('../views/order/shiplist.vue'),
      meta: {
        title: '报价货单',
        // requireAuth: false
        requireAuth: true
      }
    },
    {
      path: '/order/shiplist_fastCart',
      name: 'shiplist_fastCart',
      component: () => import('../views/order/shiplist_fastCart.vue'),
      meta: {
        title: '',
        keepAlive: true
      }
    },
    {
      path: '/user/checkUser',
      name: 'checkUser',
      component: () => import('../views/user/checkUser.vue'),
      meta: {
        title: '',
        requireAuth: true
      }
    },
    {
      path: '/order/twkerOrder',
      name: 'twkerOrder',
      component: () => import('../views/order/twkerOrder.vue'),
      meta: {
        title: '报价货单',
        // requireAuth: false
        requireAuth: true
      }
    },
    {
      path: '/order/shiplistNotWork',
      name: 'shiplistNotWork',
      component: () => import('../views/order/shiplistNotWork.vue'),
      meta: {
        title: '货单失效',
        requireAuth: false
      }
    },
    {
      path: '/order/orderDetail',
      name: 'orderDetail',
      component: () => import('../views/order/orderDetail.vue'),
      meta: {
        title: '订单详情',
        requireAuth: false
      }
    },
    {
      path: '/order/expressInfo',
      name: 'expressInfo',
      component: () => import('../views/order/expressInfo.vue'),
      meta: {
        title: '物流信息',
        requireAuth: true
        // requireAuth: false
      }
    },
    {
      path: '/order/orderReturn',
      name: 'orderReturn',
      component: () => import('../views/order/orderReturn.vue'),
      meta: {
        title: '订单售后',
        requireAuth: false
      }
    },
    {
      path: '/order/orderReturnConfirm',
      name: 'orderReturnConfirm',
      component: () => import('../views/order/orderReturnConfirm.vue'),
      meta: {
        title: '订单售后',
        requireAuth: false
      }
    },
    {
      path: '/order/returnStatus',
      name: 'returnStatus',
      component: () => import('../views/order/returnStatus.vue'),
      meta: {
        title: '订单售后',
        // requireAuth: false
        requireAuth: true
      }
    }, {
      path: '/user/aboutOus',
      name: 'aboutOus',
      component: () => import('../views/user/aboutOus.vue'),
      meta: {
        title: ' ',
        requireAuth: false
      }
    },
    // 公共页面 成功页面
    {
      path: '/publictips/success',
      name: 'success',
      component: () => import('../views/publictips/success.vue'),
      meta: {
        title: '成功提示',
        requireAuth: false
      }
    },
    {
      path: '/seckill/seckillList',
      name: 'SeckillList',
      component: () => import('../views/seckill/seckillList.vue'),
      meta: {
        title: '秒杀',
        requireAuth: false
      }
    },
    // 云课堂 首页
    {
      path: '/studyClass/studyIndex',
      name: 'studyIndex',
      component: () => import('../views/studyClass/studyIndex.vue'),
      meta: {
        title: '云课堂',
        requireAuth: false
      }
    },
    // 云课堂 课程搜索
    {
      path: '/studyClass/studySer',
      name: 'studySer',
      component: () => import('../views/studyClass/studySer.vue'),
      meta: {
        title: '课程搜索',
        requireAuth: false
      }
    },
    // 云课堂 课程详情
    {
      path: '/studyClass/studyDetail',
      name: 'studyDetail',
      component: () => import('../views/studyClass/studyDetail.vue'),
      meta: {
        title: '课程详情',
        requireAuth: false
      }
    },
    // 云课堂 课程订单
    {
      path: '/studyClass/studyOrder',
      name: 'studyOrder',
      component: () => import('../views/studyClass/studyOrder.vue'),
      meta: {
        title: '课程购买',
        requireAuth: false
      }
    },
    // 外链地址 -- 前店 后仓 发货确认页
    {
      path: '/linkPage/expressCheck',
      name: 'expressCheck',
      component: () => import('../views/linkPage/expressCheck.vue'),
      meta: {
        title: '收货确认单',
        requireAuth: true
        // requireAuth: false
      }
    },
    {
      path: '/linkPage/comfirmExpress',
      name: 'comfirmExpress',
      component: () => import('../views/linkPage/comfirmExpress.vue'),
      meta: {
        title: '收货确认单',
        requireAuth: false
      }
    },
    // 外链地址 -- 盘存 确认页
    {
      path: '/linkPage/comfirmInventory',
      name: 'comfirmInventory',
      component: () => import('../views/linkPage/comfirmInventory.vue'),
      meta: {
        title: '盘存确认单',
        requireAuth: false
      }
    },
    {
      path: '/linkPage/inventoryCheck',
      name: 'inventoryCheck',
      component: () => import('../views/linkPage/inventoryCheck.vue'),
      meta: {
        title: '盘存确认单',
        requireAuth: true
        // requireAuth: false
      }
    },
    {
      path: '/linkPage/certificationReg01',
      name: 'certificationReg01',
      component: () => import('../views/linkPage/certificationReg01.vue'),
      meta: {
        title: '兽医首选·注册认证',
        requireAuth: false
      }
    },
    {
      path: '/linkPage/certificationReg02',
      name: 'certificationReg02',
      component: () => import('../views/linkPage/certificationReg02.vue'),
      meta: {
        title: '兽医首选·注册认证',
        requireAuth: false
      }
    },
    {
      path: '/linkPage/vip',
      name: 'vip',
      component: () => import('../views/linkPage/vip.vue'),
      meta: {
        title: '会员领取',
        // requireAuth: true
        requireAuth: false
      }
    },
    {
      path: '/order/payCallBack',
      name: 'payCallBack',
      component: () => import('../views/order/payCallBack.vue'),
      meta: {
        title: '支付成功',
        requireAuth: false
      }
    },
    {
      path: '/invoice/applyInvoice',
      name: 'applyInvoice',
      component: () => import('../views/invoice/applyInvoice.vue'),
      meta: {
        title: '申请开票',
        keepAlive: true,
        requireAuth: false
      }
    },
    {
      path: '/invoice/detailInvoice',
      name: 'detailInvoice',
      component: () => import('../views/invoice/detailInvoice.vue'),
      meta: {
        title: '开票详情',
        requireAuth: false
      }
    },
    {
      path: '/invoice/chooseCompany',
      name: 'chooseCompany',
      component: () => import('../views/invoice/chooseCompany.vue'),
      meta: {
        title: '公司信息',
        requireAuth: false
      }
    }
  ]
})

// const router = new VueRouter({
//   routes
// })

// export default router
